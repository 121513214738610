let panels = {
    defaults: [
        {
            id: 'layers',
            el: '.panel__left',
            // Make the panel resizable
            resizable: {
                maxDim: 350,
                minDim: 200,
                tc: 0, // Top handler
                cl: 0, // Left handler
                cr: 1, // Right handler
                bc: 0, // Bottom handler
                // Being a flex child we need to change `flex-basis` property
                // instead of the `width` (default)
                keyWidth: 'flex-basis',
            },
        },
        {
            id: 'panel-switcher',
            el: '.panel__switcher',
            buttons: [{
                id: 'show-blocks',
                active: true,
                attributes: { title: 'Elemente'},
                className: 'fa fa-puzzle-piece',
                command: 'show-blocks',
                // Once activated disable the possibility to turn it off
                togglable: false,
            },{
                id: 'show-layers',
                active: true,
                attributes: { title: 'Aufbau'},
                className: 'fad fa-layer-group',
                command: 'show-layers',
                // Once activated disable the possibility to turn it off
                togglable: false,
            // }, {
            //     id: 'show-style',
            //     active: true,
            //     attributes: { title: 'Aussehen'},
            //     className: 'far fa-pencil-ruler',
            //     command: 'show-styles',
            //     togglable: false,
            },{
                id: 'show-traits',
                active: true,
                attributes: { title: 'Eigenschaften'},
                className: 'fa fa-cogs',
                command: 'show-traits',
                togglable: false,
            }],
        },
        {
            id: 'panel-devices',
            el: '.panel__devices',
            buttons: [{
                id: 'device-desktop',
                attributes: { title: 'Desktop-Ansicht'},
                className: 'far fa-desktop',
                command: 'set-device-desktop',
                active: true,
                togglable: false,
            }, {
                id: 'device-tablet',
                attributes: { title: 'Tablet-Ansicht'},
                className: 'far fa-tablet',
                command: 'set-device-tablet',
                togglable: false,
            }, {
                id: 'device-mobile',
                attributes: { title: 'Mobil-Ansicht'},
                className: 'far fa-mobile',
                command: 'set-device-mobile',
                togglable: false,
            }, {
                id: 'preview',
                className: 'fa fa-eye',
                command: 'preview',
                context: 'preview',
                attributes: { title: 'Preview' }
            }, {
                id: 'fullscreen',
                attributes: { title: 'Vollbild umschalten'},
                className: 'far fa-expand',
                command: 'toggle-fullscreen',
                togglable: false,
            },{
                id: 'clear-editor',
                attributes: { title: 'Clear'},
                className: 'fa fa-trash-alt',
                command: 'clear',
                // Once activated disable the possibility to turn it off
                togglable: false,
            },{
                id: 'show-experiment',
                attributes: { title: 'Experiment'},
                className: 'fa fa-wand-magic',
                command: 'experiment',
                // Once activated disable the possibility to turn it off
                togglable: false,
            }],
        },
        {
            id: 'panel-custom',
            el: '.panel__custom',
            buttons: [],
        }]
}

export default panels
