<template>
  <div class="back-crumb">
    <router-link to="/admin/events"><i class="fal fa-chevron-left"></i>Zurück zur Veranstaltungsübersicht</router-link>
  </div>
</template>

<script>
export default {
  name: "BackCrumb"
}
</script>

<style scoped>
.back-crumb {
  padding: 2px 0 12px 0;
}

.back-crumb i {
  margin-right: 8px;
}

.back-crumb a {
  color: #999;
  font-size: 12px;
  line-height: 14px;
}

.back-crumb a:hover {
  color: #555;
}
</style>
