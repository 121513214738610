<template>
  <div>
    <BackCrumb/>
    <h1 class="c-view-h1">{{ $store.state.event.name }}</h1>
    <div :style="{
      display: editorReady ? 'block' : 'none'
    }" class="editor absolute">
      <GrapesJS ref="editor" />
    </div>
  </div>
</template>

<script>
import GrapesJS from '@/components/GrapesJs/GrapesJS'
import BackCrumb from "../../../components/Admin/Event/BackCrumb";

export default {
  name: "Landingpage",
  components: {
    GrapesJS,
    BackCrumb
  },
  data() {
    return {
      editorReady: true,
      landingpage: {},
      empty: true
    }
  },
  methods: {
    async create() {
      this.landingpage.disabledAt = '1970-01-01 00:00:00'
      let id = await this.$store.createLandingpage(this.landingpage)
      await this.$store.getLandingpage(id)
      this.landingpage = Object.assign({}, this.$store.state.landingpage)
    }
  },
  async mounted() {
    this.editorReady = false;

    await this.$store.getLandingpages()
    this.empty = false

    if(this.$store.state.landingpages.length > 0) {
      await this.$store.getLandingpage(this.$store.state.landingpages[0].id)
      this.landingpage = Object.assign({}, this.$store.state.landingpage)

      window.$eventId = this.$store.state.event?.id;

      this.$refs.editor.init(this.landingpage);
    } else {
      await this.$store.createLandingpage({
        title: this.$store.state.event.name,
        elements: [],
        disabledAt: '1970-01-01 00:00:00'
      });
      this.landingpage = Object.assign({}, this.$store.state.landingpage)

      window.$eventId = this.$store.state.event?.id;

      this.$refs.editor.init(this.landingpage);
    }

    this.editorReady = true;
  }
}
</script>

<style scoped>
.editor {
  top: 7.75rem;
  bottom: 2rem;
  left: 0;
  right: 0;
  @apply max-w-screen-xl mx-auto px-6
}
.no-lp-info {
  @apply text-gray-600 text-sm flex flex-col content-center justify-center py-2;
}

.no-lp-info span {
  @apply text-center;
}
</style>
